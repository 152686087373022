import axios from 'axios'
import i18n, { t } from 'i18next'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { nl2br, convertDate, dateSort, positionSort } from '../utils/helpers'
import { colorRed, dateFormat } from '../utils/constants'
import { getDate } from '../utils/calendar'

const longDate = 'ddd, DD.MM.YYYY'

export async function fetchDossier(dossierId) {
  let result = {}

  let options = {
    url: `https://jsn.tourbase.letsgo.ch/dossiers/${dossierId}/`,
    method: 'get',
    crossDomain: true
  }
  result = await axios(options)

  const dossier = result.data.dossier.Dossier
  const travellers = {}

  dossier.Travellers.Traveller.forEach(
    t => (travellers[t.TravellerId] = `${t.FirstName} ${t.LastName}`)
  )

  return {
    sections: await getDossierItems(
      dossier.DossierItems.DossierItem,
      travellers
    ),
    general: getDossierGeneral(dossier),
    prices: getDossierPrices(dossier),
    employee: await getDossierEmployee(dossier),
    agency: getDossierAgency(dossier),
    infoPages: getDossierInfoPages(dossier.DossierItems.DossierItem),
    headerImage: getDossierHeaderImage(dossier.headerImage),
    additionalReference1: getAdditionalReference1(dossier)
  }
}

export async function fetchHotelDetails(code) {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })

  const querySnapshot = await firestore
    .collection('hotels')
    .where('lang', '==', i18n.language)
    .where('mergedCode', '==', code)
    .get()

  if (querySnapshot.empty) {
    return {}
  }

  const hotel = querySnapshot.docs[0].data()

  return {
    images: hotel.images,
    description: ((hotel.description.hotel && hotel.description.hotel.description) || (hotel.description.location && hotel.description.location.description))
  }
}

export async function fetchDestinationDetails(code) {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })

  const querySnapshot = await firestore
    .collection('destinations')
    .where('lang', '==', i18n.language)
    .where('code', '==', code)
    .get()

  if (querySnapshot.empty) {
    return {}
  }

  const destination = querySnapshot.docs[0].data()

  return {
    images: destination.images
  }
}

export async function fetchTripDetails(code) {
  const firestore = firebase.firestore()
firestore.settings({ timestampsInSnapshots: true })

  const querySnapshot = await firestore
    .collection('trips')
    .where('lang', '==', i18n.language)
    .where('mergedCode', '==', code)
    .get()

  if (querySnapshot.empty) {
    return {}
  }

  const trip = querySnapshot.docs[0].data()

  return {
    images: trip.images
  }
}

async function getDossierItems(dossier, travellers) {
  let items = []

  const flights = getFlights(dossier, travellers)
  const transports = getTransports(dossier, travellers)
  const hotels = await getHotels(dossier, travellers)
  const tours = getTours(dossier, travellers)
  const trips = await getTrips(dossier, travellers)
  const miscHotels = await getMiscHotels(dossier, travellers)
  const miscOther = await getMiscOther(dossier, travellers)
  const cars = getCars(dossier, travellers)
  const sorted = items
    .concat(flights, transports, hotels, tours, trips, miscHotels, miscOther, cars)
    .sort((a, b) => a.id - b.id)
  const sortedByDate = sorted.sort(dateSort)
  return sortedByDate
}

function getDossierGeneral(dossier) {
  return {
    dossierNumber: dossier.DossierNr,
    title: dossier.Title,
    firstDate: convertDate(dossier.FirstDate),
    lastDate: convertDate(dossier.LastDate),
    customerNumber: dossier.Customers.Customer.CustomerNr,
    travellers: dossier.Travellers.Traveller.map(t => ({
      id: t.TravellerId,
      name: `${t.FirstName} ${t.LastName}`
    })),
    createDate: convertDate(dossier.CreateDate)
  }
}

function getDossierPrices(dossier) {
  const found = dossier.DossierItems.DossierItem.filter(
    item => item.ItemPrices && item.ItemPrices.ItemPrice && item.ItemPrices.ItemPrice.reduce((acc, next) => Number(acc.TotalPrice) + Number(next.TotalPrice), { TotalPrice: 0 }) !== 0
  ).map(item => {
    let type = item['@attributes'].Type
    let beginDate = convertDate(item.BeginDate)
    let endDate = convertDate(item.EndDate)
    let normalizedMembers = item.Travellers.Traveller

    return {
      id: item['@attributes'].InvoicePosition,
      title: item[type].Title || item[type].HotelName,
      beginDate,
      endDate,
      members: normalizedMembers.map(
        member => member['@attributes'].TravellerId
      ),
      prices: getPrices(item.ItemPrices.ItemPrice, normalizedMembers.length)
    }
  })

  const summaryPrices = {
    totalPrice: 0,
    personPrices: dossier.Travellers.Traveller.map(
      ({ TravellerId, FirstName, LastName }) => ({
        id: TravellerId,
        name: `${FirstName} ${LastName}`,
        price: 0
      })
    )
  }

  found.forEach(({ members, prices: { pricePerUnit, totalPrice } }) => {
    summaryPrices.totalPrice += totalPrice

    members.forEach(id => {
      summaryPrices.personPrices
        .filter(person => person.id === id)
        .forEach(person => (person.price += pricePerUnit))
    })
  })

  const prices = {
    visibility: dossier.price,
    summaryPrices,
    items: found || []
  }

  return prices
}

async function getDossierEmployee(dossier) {
  const isAgency = !!dossier.Customers.Customer.IsAgent
  let employee = {}

  if (isAgency) {
    const customer = dossier.Customers.Customer

    employee = {
      name: customer.LastName,
      gender: 'a',
      phone: `${customer.Phone['@attributes'].AreaCode} ${customer.Phone['@attributes'].Number}`,
      mail: customer.Email,
      address: `${customer.Address1} ${customer.Address2}, ${customer.PostalCode} ${customer.City}`
    }
  } else {
    const employeeCode = dossier.Code1['@attributes'].Code

    const firestore = firebase.firestore()
    firestore.settings({ timestampsInSnapshots: true })

    const querySnapshot = await firestore
      .collection('teams')
      .where('lang', '==', i18n.language)
      .where('code', '==', employeeCode)
      .get()

    if (querySnapshot.empty) {
      return {}
    }

    const { name, gender, images, phone, mail } = querySnapshot.docs[0].data()
    employee = { name, gender, images, phone, mail }
  }

  return employee
}

function getDossierAgency(dossier) {
  const { customLogo, customColor } = dossier

  return {
    customLogo,
    customColor: customColor || colorRed,
    isAgency: dossier.Customers.Customer.IsAgent
  }
}

function getDossierHeaderImage(headerImage) {
  if (
    Object.keys(headerImage).length > 0 &&
    headerImage.constructor === Object
  ) {
    return headerImage
  }
  return {}
}

function getDossierInfoPages(dossier) {
  const rawItems =
    dossier.filter(item => item['@attributes'].Type === 'Text' && item.Text) ||
    []

  const found = rawItems.map(item => ({
    id: item['@attributes'].InvoicePosition,
    title: item.Text.Title,
    details: nl2br(item.Text.Detail)
  }))

  return found
}

function getFlights(dossier, travellers) {
  // flights that are in package as an array on single object
  let nestedFlights = []

  // flights from package will also get title from package and first flight will have general info
  dossier
    .filter(
      item =>
        item['@attributes'].Type === 'Package' &&
        item.Package.PackageItems &&
        item.Package.PackageItems.DossierItem.some(
          item => item['@attributes'].Type === 'Flight'
        )
    )
    .forEach(item => {
      nestedFlights = nestedFlights.concat(
        item.Package.PackageItems.DossierItem.filter(
          itemNested => itemNested['@attributes'].Type === 'Flight'
        ).map((itemNested, idx) => ({
          ...itemNested,
          details: idx > 0 ? '' : nl2br(item.Package.Detail),
          // title: item.Package.Title
          title:
            idx > 0
              ? `${t('dossier.flight')} ${t('dossier.item.with')} ${itemNested.Flight.Airlinecode['@attributes'].AirlineName
              }`
              : item.Package.Title
        }))
      )
    })

  // flights that are on top of dossier as flight type object
  const topFlights =
    dossier.filter(item => item['@attributes'].Type === 'Flight') || []

  const rawItems = [...nestedFlights, ...topFlights]
  let found = []

  rawItems.forEach(
    ({
      '@attributes': attributes,
      Flight: { Airlinecode, Class, Departure, Arrival, SeatString },
      BeginDate,
      Travellers,
      details,
      title
    }) => {
      found.push({
        id: `${attributes.InvoicePosition}`,
        section: t('dossier.flight'),
        icon: 'fa fa-plane',
        type: 'airlines',

        title: title || Airlinecode['@attributes'].AirlineName,
        details,

        beginDate: convertDate(BeginDate, { outFormat: longDate }),
        dates: convertDate(BeginDate),

        route: `${Departure['@attributes'].nodeValue} - ${Arrival['@attributes'].nodeValue}, ${Departure['@attributes'].Time} / ${Arrival['@attributes'].Time}`,
        info: `${t('dossier.item.with')} ${Airlinecode['@attributes'].AirlineName
          } (${Airlinecode['@attributes'].nodeValue}) ${t('dossier.item.in')} ${Class['@attributes'].ClassDescription
          } ${t('dossier.item.class')}`,
        seats: `${SeatString ? t('dossier.item.seats') + SeatString : ''}`,
        travellers: Travellers.Traveller.map(
          t => travellers[t['@attributes'].TravellerId]
        )
      })
    }
  )

  return found
}

function getTransports(dossier, travellers) {
  const rawItems =
    dossier.filter(
      item =>
        item['@attributes'] &&
        item['@attributes'].Type === 'Misc' &&
        (item.ShortDescription1.startsWith('TRA') ||
          item.ShortDescription1.startsWith('CAR'))
    ) || []

  const found = rawItems.map(item => {
    let beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
    let endDateLong = convertDate(item.EndDate, { outFormat: longDate })
    let beginDateShort = convertDate(item.BeginDate)
    let endDateShort = convertDate(item.EndDate)

    return {
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.ShortDescription1.startsWith('TRA')
        ? t('dossier.transfer')
        : t('dossier.car'),
      icon: item.ShortDescription1.startsWith('CAR')
        ? 'fa fa-car'
        : 'fa fa-forward',
      type: 'transports',

      title: item.Misc.Title,
      beginDate: beginDateLong,
      endDate: endDateLong,

      dates: `${beginDateShort}${endDateShort ? ' - ' + endDateShort : ''}`,
      subtitle: `${item.Misc.Title}`,
      details: nl2br(item.Misc.Detail),
      route:
        item.Misc.Arrival && (`${t('dossier.item.from')} ${item.Misc.Arrival['@attributes'].Time || ''} ${item.Misc.Arrival['@attributes'].Description}` + (item.Misc.Departure ? `${t('dossier.item.to')} ${item.Misc.Departure['@attributes'].Time || ''} ${item.Misc.Departure['@attributes'].Description}` : '')),
      travellers: item.Travellers.Traveller.map(
        t => travellers[t['@attributes'].TravellerId]
      )
    }
  })
  return found
}

function getMiscHotels(dossier, travellers) {
  const rawItems =
    dossier.filter(
      item =>
        item['@attributes'] &&
        item['@attributes'].Type === 'Misc' &&
        item.ShortDescription1.startsWith('HTL')
    ) || []

  const found = rawItems.map(item => {
    let beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
    let endDateLong = convertDate(item.EndDate, { outFormat: longDate })
    let beginDateShort = convertDate(item.BeginDate)
    let endDateShort = convertDate(item.EndDate)

    return {
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Misc.Title,
      icon: 'fa fa-bed',
      type: 'miscHotels',

      title: item.Misc.Title,
      beginDate: beginDateLong,
      endDate: endDateLong,

      dates: `${beginDateShort}${endDateShort ? ' - ' + endDateShort : ''}`,
      subtitle: `${item.Misc.Title}`,
      details: nl2br(item.Misc.Detail),
      travellers: item.Travellers.Traveller.map(
        t => travellers[t['@attributes'].TravellerId]
      )
    }
  })
  return found
}

function getMiscOther(dossier, travellers) {
  const rawItems =
    dossier.filter(
      item =>
        item['@attributes'] &&
        item['@attributes'].Type === 'Misc' &&
        !item.ShortDescription1.startsWith('EXC') &&
        !item.ShortDescription1.startsWith('TRA') &&
        !item.ShortDescription1.startsWith('CAR') &&
        !item.ShortDescription1.startsWith('HTL')
    ) || []

  const found = rawItems.map(item => ({
    id: `${item['@attributes'].InvoicePosition}`,
    section: item.Misc.Title,
    icon: item.ShortDescription1.startsWith('FLT') ? 'fa fa-plane' : 'ta-info',
    type: 'miscOther',

    title: item.Misc.Title,
    beginDate: convertDate(item.BeginDate, { outFormat: longDate }),

    dates: convertDate(item.BeginDate),
    subtitle: `${item.Misc.Title}`,
    details: nl2br(item.Misc.Detail),
    travellers: item.Travellers.Traveller.map(
      t => travellers[t['@attributes'].TravellerId]
    )
  }))
  return found
}

async function getHotels(dossier, travellers) {
  const rawItems =
    dossier.filter(item => item['@attributes'].Type === 'Hotel') || []

  const found = []
  const inProgram = new Set()

  for (let item of rawItems) {
    let beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
    let endDateLong = convertDate(item.EndDate, { outFormat: longDate })
    let beginDateShort = convertDate(item.BeginDate)
    let endDateShort = convertDate(item.EndDate)
    let code = (item.Hotel.Destination || '') + (item.Hotel.HotelCode || '')
    let uniqueName = code + item.Hotel.HotelName
    let details = ''

    if (code && !inProgram.has(uniqueName)) {
      details = await fetchHotelDetails(code)
      inProgram.add(uniqueName)
    }

    found.push({
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Hotel.HotelName,
      icon: 'fa fa-bed',
      type: 'hotels',

      title: item.Hotel.HotelName,
      rating: item.Hotel.Category,
      beginDate: beginDateLong,
      endDate: endDateLong,
      dates: `${beginDateShort} - ${endDateShort}`,
      subtitle: `${item.Hotel.HotelName}, ${item.Hotel.City}`,
      remark: item.Hotel.Remark,
      nrRooms: item.Hotel.NrRooms,
      constructionSite: {
        title: item.ItemInfos && item.ItemInfos.ItemInfo && item.ItemInfos.ItemInfo.Title,
        text: item.ItemInfos && item.ItemInfos.ItemInfo && nl2br(item.ItemInfos.ItemInfo.Text)
      },
      overview: {
        room: `${item.Hotel.RoomType}`,
        included: `${item.Hotel.Included}`
      },
      details,

      travellers: item.Travellers.Traveller.map(
        t => travellers[t['@attributes'].TravellerId]
      )
    })
  }

  return found
}

function getTours(dossier, travellers) {
  const rawItems =
    dossier.filter(
      item =>
        item['@attributes'].Type === 'Package' &&
        item.ShortDescription1.startsWith('RTP')
    ) || []

  const found = rawItems.map(item => {
    const beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
    const endDateLong = convertDate(item.EndDate, { outFormat: longDate })
    const beginDateShort = convertDate(item.BeginDate)
    const endDateShort = convertDate(item.EndDate)

    let overview = item.Package.PackageItems.DossierItem.find(
      item => item['@attributes'].Type === 'Misc' && (!item.ShortDescription1 || !item.ShortDescription1.startsWith('TRA'))
    )

    let items = []
    // let hotels = []

    item.Package.PackageItems.DossierItem.filter(
      item =>
        item['@attributes'].Type === 'Text' ||
        item['@attributes'].Type === 'Hotel' ||
        (item['@attributes'].Type === 'Misc' && item.ShortDescription1.startsWith('TRA')) ||
        item['@attributes'].Type === 'Car' ||
        (item['@attributes'].Type === 'Misc' && item.ShortDescription1.startsWith('EXC'))
    ).forEach((item, idx) => {
      let beginShort = convertDate(item.BeginDate)
      let endShort = convertDate(item.EndDate)

      if (item.Text && item.BeginDate && !item.Text.TextCode) {
        items.push({
          id: `${item['@attributes'].InvoicePosition}-${idx}`,
          type: 'text',
          start: beginShort,
          end: endShort,
          date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
          title: `${item.Text.Title}`,
          details: nl2br(item.Text.Detail),
          position: item['@attributes'].Position,
          hotels: []
        })
      } else if (item.Text && item.Text.TextCode) {
        items.push({
          id: `${item.Text.TextCode}${item['@attributes'].InvoicePosition}`,
          type: 'region',
          title: `${item.Text.Title}`,
          details: nl2br(item.Text.Detail),
          position: item['@attributes'].Position,
          code: item.Text.TextCode
        })
      } else if (item.Hotel) {
        const hotel = {
          id:
            (item.Hotel.Destination || '') +
            (item.Hotel.HotelCode || '') +
            item['@attributes'].InvoicePosition,
          type: 'hotel',
          start: beginShort,
          end: endShort,
          date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
          title: item.Hotel.HotelName,
          rating: item.Hotel.Category || 0,
          room: item.Hotel.RoomType,
          remark: item.Hotel.Remark,
          nrRooms: item.Hotel.NrRooms,
          constructionSite: {
            title: item.ItemInfos && item.ItemInfos.ItemInfo && item.ItemInfos.ItemInfo.Title,
            text: item.ItemInfos && item.ItemInfos.ItemInfo && nl2br(item.ItemInfos.ItemInfo.Text)
          },
          included: item.Hotel.Included,
          city: item.Hotel.City,
          code: (item.Hotel.Destination || '') + (item.Hotel.HotelCode || ''),
          position: item['@attributes'].Position,
        }
        items.reduceRight((skip, curr) => {
          if(skip) return skip
          if ((curr.type === 'text' || curr.type === 'transfer' || curr.type === 'trips') && curr.start && !curr.end) {
            const hotelStart = getDate(hotel.start, dateFormat)
            const hotelEnd = getDate(hotel.end, dateFormat)
            const dayStart = getDate(curr.start, dateFormat)

            if (dayStart < hotelEnd && dayStart >= hotelStart) {
              curr.hotels.push(hotel)
              return true
            }
          }
          return skip
        }, false)
        // hotels.push({
        //   id:
        //     (item.Hotel.Destination || '') +
        //     (item.Hotel.HotelCode || '') +
        //     item['@attributes'].InvoicePosition,
        //   type: 'hotel',
        //   start: beginShort,
        //   end: endShort,
        //   date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
        //   title: item.Hotel.HotelName,
        //   rating: item.Hotel.Category || 0,
        //   room: item.Hotel.RoomType,
        //   included: item.Hotel.Included,
        //   city: item.Hotel.City,
        //   code: (item.Hotel.Destination || '') + (item.Hotel.HotelCode || ''),
        //   position: item['@attributes'].Position,
        // })
      } else if (item.Misc) {
        if(item.ShortDescription1.startsWith('TRA')) {
          items.push({
            id:
              (item.Misc.MiscItemCode || '') +
              (item.Misc.MiscCode || '') +
              item['@attributes'].InvoicePosition,
            type: 'transfer',
            title: item.Misc.Title,
            details: nl2br(item.Misc.Detail),
            start: beginShort,
            end: endShort,
            date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
            position: item['@attributes'].Position,
            hotels: []
          })
        } else if (item.ShortDescription1.startsWith('EXC')) {
          items.push({
            id: (item.Misc.MiscItemCode || '') +
            (item.Misc.MiscCode || '') +
            item['@attributes'].InvoicePosition,
            type: 'trips',
            position: item['@attributes'].Position,
            title: item.Misc.Title,
            start: beginShort,
            end: endShort,
            date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
            subtitle: "",
            details: nl2br(item.Misc.Detail),
            code: (item.Misc.Destination || '') + (item.Misc.MiscCode || ''),
            hotels: []
          })
        }
      } else if (item.Car) {
        const pickUpDropOff =
          item.Car && `<span>
            ${item.Car.Pickup && item.Car.Pickup['@attributes'].Time ? `<span><b>${t('dossier.pickup')}: </b>${item.Car.Pickup['@attributes'].Time}${item.Car.Pickup && item.Car.Pickup['@attributes'].Description ? ` - ${item.Car.Pickup['@attributes'].Description}` : ''}</span>` : ''}
            ${item.Car.Dropoff && item.Car.Dropoff['@attributes'].Time ? ` / <span><b>${t('dossier.dropoff')}: </b>${item.Car.Dropoff['@attributes'].Time}${item.Car.Dropoff && item.Car.Dropoff['@attributes'].Description ? ` - ${item.Car.Dropoff['@attributes'].Description}` : ''}</span>` : ''}
          </span>`
        items.push({
          id:
            (item.Car.CarItemCode || '') +
            (item.Car.CarCode || '') +
            item['@attributes'].InvoicePosition,
          type: 'car',
          title: `${item.Car.Type}`,
          details: nl2br(item.Car.Included),
          subtitle: pickUpDropOff,
          start: beginShort,
          end: endShort,
          date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
          position: item['@attributes'].Position,
          hotels: []
        })
      }
    })

    // hotels.forEach(hotel => {
    //   items
    //     .filter(i => (i.type === 'text' || i.type === 'transfer') && i.start && !i.end)
    //     .forEach(item => {
    //       const hotelStart = getDate(hotel.start, dateFormat)
    //       const hotelEnd = getDate(hotel.end, dateFormat)
    //       const dayStart = getDate(item.start, dateFormat)

    //       if (dayStart < hotelEnd && dayStart >= hotelStart && (item.position && (Number(item.position) + 1) === Number(hotel.position))) {
    //         item.hotels.push(hotel)
    //       }
    //     })
    // })

    return {
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Package.Title,
      icon: 'fa fa-compass',
      type: 'tours',
      position: item['@attributes'].Position,

      title: item.Package.Title,
      beginDate: beginDateLong,
      endDate: endDateLong,
      dates: `${beginDateShort}${endDateShort ? ' - ' + endDateShort : ''}`,
      subtitle: `${item.Package.Title}`,

      overview: overview ? {
        title: overview.Misc && overview.Misc.Title ? overview.Misc.Title : '',
        details: overview.Misc && overview.Misc.Detail ? nl2br(overview.Misc.Detail) : ''
      } : undefined,
      items,
      travellers: item.Travellers.Traveller.map(
        t => travellers[t['@attributes'].TravellerId]
      )
    }
  })
  const sortedByDate = found.sort(dateSort)
  const sortedPosition = sortedByDate.sort(positionSort)
  return sortedPosition
}

async function getTrips(dossier, travellers) {
  const rawItems =
    dossier.filter(
      item =>
        item['@attributes'].Type === 'Misc' &&
        item.ShortDescription1.startsWith('EXC')
    ) || []

  const found = []

  for (let item of rawItems) {
    let code = (item.Misc.Destination || '') + (item.Misc.MiscCode || '')
    let description = ''

    if (code) {
      description = await fetchTripDetails(code)
    }

    found.push({
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Misc.Title,
      icon: 'icon-custom rucksack',
      type: 'trips',

      title: item.Misc.Title,
      beginDate: convertDate(item.BeginDate, { outFormat: longDate }),

      dates: convertDate(item.BeginDate),
      subtitle: `${item.Misc.Title}`,

      arrival: {
        time: `${(item.Misc.Arrival && item.Misc.Arrival['@attributes'].Time) ||
          ''}`,
        title: `${(item.Misc.Arrival &&
          item.Misc.Arrival['@attributes'].Description) ||
          ''}`
      },
      departure: {
        time: `${(item.Misc.Departure &&
          item.Misc.Departure['@attributes'].Time) ||
          ''}`,
        title: `${(item.Misc.Departure &&
          item.Misc.Departure['@attributes'].Description) ||
          ''}`
      },

      details: nl2br(item.Misc.Detail),
      description,
      travellers: item.Travellers.Traveller.map(
        t => travellers[t['@attributes'].TravellerId]
      )
    })
  }

  return found
}

function getPrices(itemPrice, unitNumber) {
  const calculatedPrices = itemPrice.reduce(
    (prev, curr) => {
      return {
        totalPrice: Number(prev.totalPrice) + Number(curr.TotalPrice),
        subprices: [
          ...prev.subprices,
          {
            description: curr.PriceDescription,
            members: curr.PriceTravellers.PriceTraveller.map(
              member => member['@attributes'].TravellerId
            ),
            pricePerUnit: Number(curr.PricePerUnit),
            totalPrice: Number(curr.TotalPrice)
          }
        ]
      }
    },
    { totalPrice: 0, subprices: [] }
  )

  calculatedPrices.pricePerUnit =
    Number(calculatedPrices.totalPrice) / Number(unitNumber)

  return calculatedPrices
}

function getCars(dossier, travellers) {
  const rawItems =
    dossier.filter(
      item =>
        item['@attributes'] &&
        item['@attributes'].Type === 'Car'
    ) || []

  const found = rawItems.map(item => {
    let beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
    let endDateLong = convertDate(item.EndDate, { outFormat: longDate })
    let beginDateShort = convertDate(item.BeginDate)
    let endDateShort = convertDate(item.EndDate)
    const pickUpDropOff =
      item.Car && `<span>
        ${item.Car.Pickup && item.Car.Pickup['@attributes'].Time ? `<span><b>${t('dossier.pickup')}: </b>${item.Car.Pickup['@attributes'].Time}${item.Car.Pickup && item.Car.Pickup['@attributes'].Description ? ` - ${item.Car.Pickup['@attributes'].Description}` : ''}</span>` : ''}
        ${item.Car.Dropoff && item.Car.Dropoff['@attributes'].Time ? ` / <span><b>${t('dossier.dropoff')}: </b>${item.Car.Dropoff['@attributes'].Time}${item.Car.Dropoff && item.Car.Dropoff['@attributes'].Description ? ` - ${item.Car.Dropoff['@attributes'].Description}` : ''}</span>` : ''}
      </span>`

    return {
      id: `${item['@attributes'].InvoicePosition}`,
      section: t('dossier.car'),
      icon: 'fa fa-car',
      type: 'transports',
      title: `${item.Car.Type}`,
      beginDate: beginDateLong,
      endDate: endDateLong,

      dates: `${beginDateShort}${endDateShort ? ' - ' + endDateShort : ''}`,
      subtitle: `${item.ShortDescription2}`,
      details: pickUpDropOff,
      route: nl2br(item.Car.Included),
      travellers: item.Travellers.Traveller.map(
        t => travellers[t['@attributes'].TravellerId]
      )
    }
  })
  return found
}

function getAdditionalReference1(dossier) {
  return {
    ...(dossier.AdditionalReference1 ? dossier.AdditionalReference1['@attributes'] : {})
  }
}
