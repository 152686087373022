import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Header, Accordion, Icon, Divider, List } from 'semantic-ui-react'
import StarCounter from '../Common/StarCounter'

import HotelDescriptions from './HotelDescriptions'
import ImageHeaderSlider from '../Items/ImageHeaderSlider'

import { fetchHotelDetails, fetchDestinationDetails, fetchTripDetails } from '../../utils/dossier'

class TourProgram extends Component {
  state = { activeAccordion: '', hotelDetails: '', destinationDetails: '', tripDetails: '' }

  componentDidMount = () => {
    if (!this.props.onImage || this.props.hasImage) return
    const { tour: { items } } = this.props
    if(items && items.length > 0) {
      const hasHotelCode = items.some(item => !!item.hotels && item.hotels.length > 0 && item.hotels.some(hotel => hotel.code))
      this.props.onImage(hasHotelCode)
    }
  }

  handleHotel = (index, code) => {
    const { activeAccordion } = this.state
    const open = activeAccordion !== index

    if (open && code) {
      fetchHotelDetails(code)
        .then(hotelDetails => this.setState({ activeAccordion: index, hotelDetails }))
    } else if (open) {
      this.setState({ activeAccordion: index, hotelDetails: '' })
    } else {
      this.setState({ activeAccordion: '', hotelDetails: '' })
    }
  }

  handleTrip = (index, code) => {
    const { activeAccordion } = this.state
    const open = activeAccordion !== index

    if (open && code) {
      fetchTripDetails(code)
        .then(tripDetails => this.setState({ activeAccordion: index, tripDetails }))
    } else if (open) {
      this.setState({ activeAccordion: index, tripDetails: '' })
    } else {
      this.setState({ activeAccordion: '', tripDetails: '' })
    }
  }

  fetchRegion = (index, code) => {
    const { activeAccordion } = this.state
    const open = activeAccordion !== index

    if (open && code) {
      fetchDestinationDetails(code)
        .then(destinationDetails => this.setState({ activeAccordion: index, destinationDetails }))
    } else if (open) {
      this.setState({ activeAccordion: index, destinationDetails: '' })
    } else {
      this.setState({ activeAccordion: '', destinationDetails: '' })
    }
  }

  render () {
    const { t, color, tour: { dates, subtitle, overview, items } } = this.props
    const { activeAccordion, hotelDetails, destinationDetails, tripDetails } = this.state

    return (
      <div>
        {/* SUBTITLE */}
        <p><span style={{ color }}>{dates}</span> <strong>{subtitle}</strong></p>

        {/* OVERVIEW INFO */}
        {overview && <p><strong>{overview.title}</strong><br /><span dangerouslySetInnerHTML={{ __html: overview.details }} /></p>}

        {/* TOUR PROGRAM */}
        {items && items.map(({ id, type, date, title, details, code, rating, hotels, city, subtitle }) => (
          <div key={id}>
            {/* DAY PROGRAM */}
            {(type === 'text' || type === 'transfer' || type === 'car' || type === 'trips') &&
              <div style={{ marginBottom: 10 }}>
                <Divider className='bold' style={{ borderColor: color }} />
                <Header as='h3' style={{ color }}>
                  <Header.Subheader>{date}</Header.Subheader>
                  {title}
                </Header>
                {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
                <p><strong><span style={{ color }}>{t('dossier.program.program')}:</span></strong></p>
                <p dangerouslySetInnerHTML={{ __html: details }} />
              </div>
            }
            {/* ACCORDIONS */}
            <Accordion fluid>
              {type === 'region' &&
              <React.Fragment>
                <Accordion.Title index={id} active={activeAccordion === id} onClick={(e, { index }) => this.fetchRegion(index, code)}>
                  <Icon name='dropdown' />
                  <span style={{ color }}>{t('dossier.program.region')}:</span> {title}
                </Accordion.Title>
                <Accordion.Content active={activeAccordion === id}>
                  {activeAccordion === id &&
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: details }} />
                    {destinationDetails && destinationDetails.images && <ImageHeaderSlider images={destinationDetails.images.standard} aspectRatio={1.5} noTransform />}
                  </div>
                  }
                </Accordion.Content>
              </React.Fragment>
              }
              {type === 'trips' && tripDetails &&
                <React.Fragment>
                  <Accordion.Title index={id} active={activeAccordion === id} onClick={(e, { index }) => this.handleTrip(index, code)}>
                    <Icon name='dropdown' />
                    <span style={{ color }}>{t('dossier.program.trip')}:</span> {title}
                  </Accordion.Title>
                  <Accordion.Content active={activeAccordion === id}>
                    {activeAccordion === id &&
                    <div>
                      {tripDetails && tripDetails.images && <ImageHeaderSlider images={tripDetails.images.standard} aspectRatio={1.5} noTransform />}
                    </div>
                    }
                  </Accordion.Content>
                </React.Fragment>
              }
              {hotels && !!hotels.length && hotels.map(hotel => (
                <React.Fragment key={hotel.id + id}>
                  <Accordion.Title index={hotel.id + id} active={activeAccordion === hotel.id + id} onClick={(e, { index }) => this.handleHotel(index, hotel.code)}>
                    <Icon name='dropdown' />
                    <span style={{ color }}>{t('dossier.program.accommodation')}:</span> {hotel.title} <StarCounter count={hotel.rating} color={color} />, {hotel.city} ({hotel.date})
                  </Accordion.Title>
                  <Accordion.Content active={activeAccordion === hotel.id + id}>
                    {activeAccordion === hotel.id + id &&
                      <div style={{ marginBottom: 10 }}>
                        <List bulleted>
                          {hotel.room && <List.Item>{hotel.nrRooms && `${hotel.nrRooms}x `}{hotel.room}</List.Item>}
                          {hotel.included && <List.Item>{hotel.included}</List.Item>}
                          {hotel.remark && <List.Item>{hotel.remark}</List.Item>}
                        </List>
                        {hotelDetails && hotelDetails.description && <HotelDescriptions slider lightboxId={`tourhotel-${id}`} {...hotelDetails} />}
                      </div>
                    }
                  </Accordion.Content>
                </React.Fragment>
              ))}
            </Accordion>
          </div>
        ))}
      </div>
    )
  }
}

export default withTranslation()(TourProgram)
